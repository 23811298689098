import { Component, Input } from '@angular/core';
import { Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'xpw-checkbox-group',
  template: `
    <nz-checkbox-group
      [(ngModel)]="options"
      (ngModelChange)="emitValue()"
    ></nz-checkbox-group>
  `,
  styleUrls: ['./xpw-checkbox.component.less'],
})
export class XpwCheckboxComponent {
  //implements OnChanges
  @Input() options: CheckboxOptionType[];
  @Output() optionsChange = new EventEmitter<CheckboxOptionType[]>();

  // ngOnChanges(changes: SimpleChanges) {
  //   console.log('changes', changes);
  //   if (changes['options']) {
  //     this.options = changes['options'].currentValue;
  //   }
  // }

  emitValue(): void {
    this.optionsChange.emit(this.options);
  }
}
export interface CheckboxOptionType {
  label: string;
  value: string | number;
  checked?: boolean;
  disabled?: boolean;
}
