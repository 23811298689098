import { Injectable } from '@angular/core';
import { OrganizationsService } from '@core/api/organizations.service';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { forkJoin, of } from 'rxjs';
import {
  catchError,
  distinctUntilChanged,
  filter,
  map,
  mergeMap,
  tap,
  withLatestFrom,
} from 'rxjs/operators';
import * as OrganizationActions from './organizations.actions';
import { GlobalFunctions } from '@shared/utility/global-functions';
import { initialOrganizationState } from './organizations.state';
import {
  selectChannelConfigurationsOrganizationUID,
  selectDepartmentUID,
  selectOrganizationFilter,
  selectOrganizationPagingRequest,
  selectOrganizationSearch,
  selectSelectedDataSourceSettings,
  selectSelectedDataSourceorganizationUId,
  selectSelectedOrganization,
} from './organizations.selector';
import { Store } from '@ngrx/store';
import { NotificationService } from '@core/services/notifications/notifications.service';
import {
  selectCurrentUserDepartmentUID,
  selectCurrentUserOrganizationUID,
} from 'src/app/store/current-user/current-user.selector';

@Injectable()
export class OrganizationEffects {
  constructor(
    private actions$: Actions,
    private apiService: OrganizationsService,
    private notification: NotificationService,
    private store: Store,
  ) {}

  pagingChange$ = createEffect(() =>
    this.actions$.pipe(
      ofType(OrganizationActions.paginationChange),
      distinctUntilChanged(
        (prev, current) =>
          prev.pagingRequest.pageNumber === current.pagingRequest.pageNumber &&
          prev.pagingRequest.pageSize === current.pagingRequest.pageSize,
      ),
      map((action) =>
        OrganizationActions.getOrganizationList({
          pagingRequest: action.pagingRequest,
        }),
      ),
    ),
  );

  getOrganizationsFilters$ = createEffect(() =>
    this.actions$.pipe(
      ofType(OrganizationActions.GetOrganizationsFilters),
      withLatestFrom(this.store.select(selectDepartmentUID)),
      mergeMap(([action, departmentUID]) =>
        this.apiService.getOrganizationsFilters(departmentUID).pipe(
          map((filterOptions) =>
            OrganizationActions.GetOrganizationsFiltersSuccess({
              filterOptions: filterOptions?.filters,
            }),
          ),
          catchError((error) =>
            of(OrganizationActions.showErrors({ message: error })),
          ),
        ),
      ),
    ),
  );

  filterChange$ = createEffect(() =>
    this.actions$.pipe(
      ofType(OrganizationActions.filterChange),
      withLatestFrom(
        this.store.select(selectDepartmentUID),
        this.store.select(selectOrganizationPagingRequest),
      ),
      filter(
        ([action, departmentUID, pagingrequest]) =>
          departmentUID != '0' && departmentUID != '',
      ),
      map(([action, departmentUID, pagingrequest]) =>
        OrganizationActions.getOrganizationList({
          pagingRequest: {
            ...initialOrganizationState.pagingRequest,
            pageSize: pagingrequest.pageSize,
            orderBy: pagingrequest.orderBy,
            isDescending: pagingrequest.isDescending,
          },
        }),
      ),
    ),
  );

  getOrganizationList$ = createEffect(() =>
    this.actions$.pipe(
      ofType(OrganizationActions.getOrganizationList),
      withLatestFrom(
        this.store.select(selectDepartmentUID),
        this.store.select(selectOrganizationFilter),
        this.store.select(selectOrganizationSearch),
      ),

      filter(
        ([action, departmentUID, filter, searchText]) =>
          departmentUID != '0' && departmentUID != '',
      ),
      mergeMap(([action, departmentUID, filter, searchText]) =>
        forkJoin(
          this.apiService.getOrganizations(
            action.pagingRequest,
            departmentUID,
            filter,
            searchText,
          ),
          this.apiService.getOrganizationTypes(departmentUID),
        ).pipe(
          mergeMap((response) => [
            OrganizationActions.getOrganizationListSuccess({
              organizations: response[0],
              organizationTypes: GlobalFunctions.convertToEnum(
                response[1],
                'organizationTypeId',
              ),
            }),
          ]),
          catchError((error) =>
            of(OrganizationActions.showErrors({ message: error })),
          ),
        ),
      ),
    ),
  );

  getOrganizationTypes$ = createEffect(() =>
    this.actions$.pipe(
      ofType(OrganizationActions.getOrganizationTypes),
      withLatestFrom(this.store.select(selectDepartmentUID)),
      mergeMap(([actions, departmentUID]) =>
        this.apiService.getOrganizationTypes(departmentUID).pipe(
          map((organizationTypes) =>
            OrganizationActions.getOrganizationTypesSuccess({
              organizationTypes,
            }),
          ),
          catchError((error) =>
            of(OrganizationActions.showErrors({ message: error })),
          ),
        ),
      ),
    ),
  );

  getLastProcessing$ = createEffect(() =>
    this.actions$.pipe(
      ofType(OrganizationActions.GetLastProcessing),
      withLatestFrom(
        this.store.select(selectCurrentUserOrganizationUID),
        this.store.select(selectCurrentUserDepartmentUID),
      ),
      mergeMap(([actions, organizationUID, departmentUID]) =>
        this.apiService.getLastProcessing(organizationUID, departmentUID).pipe(
          map((lastProcessing) =>
            OrganizationActions.GetLastProcessingSuccess({ lastProcessing }),
          ),
          catchError((error) =>
            of(OrganizationActions.showErrors({ message: error })),
          ),
        ),
      ),
    ),
  );

  setUpdateOrganization$ = createEffect(() =>
    this.actions$.pipe(
      ofType(OrganizationActions.updateOrganization),
      tap(() => this.notification.info(undefined, 'Updating Organization...')),
      withLatestFrom(this.store.select(selectDepartmentUID)),
      mergeMap(([action, departmentUID]) =>
        this.apiService
          .updateOrganization(action.organization, departmentUID)
          .pipe(
            tap(() =>
              this.notification.success(undefined, 'Organization updated.'),
            ),
            mergeMap((organization) =>
              of(
                OrganizationActions.getOrganizationSuccess({
                  organization,
                }),
                OrganizationActions.UpdateOrganizationSuccess(),
              ),
            ),
            catchError((error) =>
              of(
                OrganizationActions.validationError({
                  ValidationErrors: error,
                }),
              ),
            ),
          ),
      ),
    ),
  );

  setCreateNewOrganization$ = createEffect(() =>
    this.actions$.pipe(
      ofType(OrganizationActions.setNewOrganization),
      tap(() => this.notification.info(undefined, 'Creating Organization...')),
      withLatestFrom(this.store.select(selectDepartmentUID)),
      mergeMap(([action, departmentUID]) =>
        this.apiService
          .createOrganization(action.organization, departmentUID)
          .pipe(
            tap(() =>
              this.notification.success(undefined, 'Organization created.'),
            ),
            mergeMap((organization) =>
              of(
                OrganizationActions.getOrganizationSuccess({
                  organization,
                }),
                OrganizationActions.createNewOrganizationSuccess(),
              ),
            ),
            catchError((error) =>
              of(
                OrganizationActions.validationError({
                  ValidationErrors: error,
                }),
              ),
            ),
          ),
      ),
    ),
  );

  updatedOrganization$ = createEffect(() =>
    this.actions$.pipe(
      ofType(OrganizationActions.getOrganizationSuccess),
      withLatestFrom(this.store.select(selectOrganizationPagingRequest)),
      map(([action, pagingRequest]) =>
        OrganizationActions.getOrganizationList({
          pagingRequest: pagingRequest,
        }),
      ),
    ),
  );

  deleteOrganization$ = createEffect(() =>
    this.actions$.pipe(
      ofType(OrganizationActions.deleteOrganization),
      withLatestFrom(
        this.store.select(selectDepartmentUID),
        this.store.select(selectOrganizationPagingRequest),
      ),
      mergeMap(([action, departmentUID, pagingRequest]) =>
        this.apiService
          .deleteOrganization(action.organizationUID, departmentUID)
          .pipe(
            mergeMap((result) =>
              of(
                OrganizationActions.getOrganizationList({
                  pagingRequest: pagingRequest,
                }),
                OrganizationActions.showSuccessMessage({
                  message: 'Organization deleted.',
                }),
              ),
            ),
            catchError((error) =>
              of(OrganizationActions.OrganizationError({ error })),
            ),
          ),
      ),
    ),
  );

  activateOrganization$ = createEffect(() =>
    this.actions$.pipe(
      ofType(OrganizationActions.activateOrganization),
      withLatestFrom(
        this.store.select(selectDepartmentUID),
        this.store.select(selectOrganizationPagingRequest),
      ),
      mergeMap(([action, departmentUID, pagingRequest]) =>
        this.apiService
          .activateOrganization(action.organizationUID, departmentUID)
          .pipe(
            mergeMap((result) =>
              of(
                OrganizationActions.getOrganizationList({
                  pagingRequest: pagingRequest,
                }),
                OrganizationActions.showSuccessMessage({
                  message: 'Organization activated.',
                }),
              ),
            ),
            catchError((error) =>
              of(OrganizationActions.showErrors({ message: error })),
            ),
          ),
      ),
    ),
  );

  deactivateOrganization$ = createEffect(() =>
    this.actions$.pipe(
      ofType(OrganizationActions.deactivateOrganization),
      withLatestFrom(
        this.store.select(selectDepartmentUID),
        this.store.select(selectOrganizationPagingRequest),
      ),
      mergeMap(([action, departmentUID, pagingRequest]) =>
        this.apiService
          .deActivateOrganization(action.organizationUID, departmentUID)
          .pipe(
            mergeMap((result) =>
              of(
                OrganizationActions.getOrganizationList({
                  pagingRequest,
                }),
                OrganizationActions.showSuccessMessage({
                  message: 'Organization deactivated.',
                }),
              ),
            ),
            catchError((error) =>
              of(OrganizationActions.showErrors({ message: error })),
            ),
          ),
      ),
    ),
  );

  getOrganizationApiCredential$ = createEffect(() =>
    this.actions$.pipe(
      ofType(OrganizationActions.getOrganizationApiCredentials),
      withLatestFrom(this.store.select(selectDepartmentUID)),
      mergeMap(([action, departmentUID]) =>
        this.apiService
          .getOrganizationApiCredentials(action.organizationUID, departmentUID)
          .pipe(
            map((result) =>
              OrganizationActions.getOrganizationApiCredentialsSuccess({
                organizationUID: action.organizationUID,
                orgApiCredentials: result,
              }),
            ),
            catchError((error) =>
              of(OrganizationActions.showErrors({ message: error })),
            ),
          ),
      ),
    ),
  );

  updateOrganizationApiCredentials$ = createEffect(() =>
    this.actions$.pipe(
      ofType(OrganizationActions.updateOrganizationApiCredentials),
      withLatestFrom(this.store.select(selectDepartmentUID)),
      mergeMap(([action, departmentUID]) =>
        this.apiService
          .updateOrganizationApiCredentials(
            {
              organizationUID: action.organizationUID,
              apiCredentials: action.apiCredentials,
            },
            departmentUID,
          )
          .pipe(
            // withLatestFrom(this.store.select(selectDepartmentUID)),
            mergeMap(() =>
              of(
                OrganizationActions.getOrganizationApiCredentials({
                  organizationUID: action.organizationUID,
                }),
                OrganizationActions.updateOrganizationApiCredentialsSuccess(),
                OrganizationActions.showSuccessMessage({
                  message: 'Organization API credentials updated.',
                }),
              ),
            ),
            catchError((error) =>
              of(
                OrganizationActions.validationError({
                  ValidationErrors: error,
                }),
              ),
            ),
          ),
      ),
    ),
  );

  renewSecretOrganizationApiCredentials$ = createEffect(() =>
    this.actions$.pipe(
      ofType(OrganizationActions.renewSecretOrganizationApiCredentials),
      withLatestFrom(this.store.select(selectDepartmentUID)),
      mergeMap(([action, departmentUID]) =>
        this.apiService
          .renewSecretOrganizationApiCredentials(
            {
              organizationUID: action.organizationUID,
            },
            departmentUID,
          )
          .pipe(
            // withLatestFrom(this.store.select(selectDepartmentUID)),
            mergeMap(() =>
              of(
                OrganizationActions.getOrganizationApiCredentials({
                  organizationUID: action.organizationUID,
                }),
                OrganizationActions.renewSecretOrganizationApiCredentialsSuccess(),
                OrganizationActions.showSuccessMessage({
                  message: 'Organization api credentials secret renewed.',
                }),
              ),
            ),
            catchError((error) =>
              of(
                OrganizationActions.validationError({
                  ValidationErrors: error,
                }),
              ),
            ),
          ),
      ),
    ),
  );

  getOrganizationRequestList$ = createEffect(() =>
    this.actions$.pipe(
      ofType(OrganizationActions.getOrganizationRequests),
      withLatestFrom(this.store.select(selectDepartmentUID)),
      mergeMap(([action, departmentUID]) =>
        this.apiService
          .getOrganizationRequests(action.organizationUID, departmentUID)
          .pipe(
            map((result) =>
              OrganizationActions.getOrganizationRequestsSuccess({
                organizationUID: action.organizationUID,
                orgRequestList: result,
              }),
            ),
            catchError((error) =>
              of(OrganizationActions.showErrors({ message: error })),
            ),
          ),
      ),
    ),
  );

  updateOrganizationRequestList$ = createEffect(() =>
    this.actions$.pipe(
      ofType(OrganizationActions.updateOrganizationRequests),
      withLatestFrom(this.store.select(selectDepartmentUID)),
      mergeMap(([action, departmentUID]) =>
        this.apiService
          .updateOrganizationRequest(
            {
              organizationUID: action.organizationUID,
              organizationRequests: action.organizationRequests,
            },
            departmentUID,
          )
          .pipe(
            withLatestFrom(this.store.select(selectDepartmentUID)),
            mergeMap(() =>
              of(
                OrganizationActions.getOrganizationRequests({
                  organizationUID: action.organizationUID,
                }),
                OrganizationActions.updateOrganizationRequestsSuccess(),
                OrganizationActions.showSuccessMessage({
                  message: 'Organization requests settings updated.',
                }),
              ),
            ),
            catchError((ValidationErrors) =>
              of(
                OrganizationActions.validationError({
                  ValidationErrors,
                }),
              ),
            ),
          ),
      ),
    ),
  );

  getDataSourceSettingsList$ = createEffect(() =>
    this.actions$.pipe(
      ofType(OrganizationActions.getDataSourceSettingsList),
      withLatestFrom(
        this.store.select(selectDepartmentUID),
        this.store.select(selectSelectedDataSourceorganizationUId),
      ),
      mergeMap(([action, departmentUID, selectedDataSourceOrganizationUID]) =>
        this.apiService
          .getDataSourceSettingsList(action.organizationUID, departmentUID)
          .pipe(
            mergeMap((result) =>
              of(
                OrganizationActions.getDataSourceTypes({
                  organizationUID: action.organizationUID,
                }),
                OrganizationActions.getDataSourceSettingsListSuccess({
                  organizationUID: action.organizationUID,
                  dataSourceSettingsList: result,
                }),
              ),
            ),
            catchError((error) =>
              of(OrganizationActions.showErrors({ message: error })),
            ),
          ),
      ),
    ),
  );

  getDataSourceTypes$ = createEffect(() =>
    this.actions$.pipe(
      ofType(OrganizationActions.getDataSourceTypes),
      withLatestFrom(this.store.select(selectDepartmentUID)),
      mergeMap(([action, departmentUID]) =>
        this.apiService
          .getDataSourceSettingsSourcetypes(
            action.organizationUID,
            departmentUID,
          )
          .pipe(
            map((result) =>
              OrganizationActions.GetDataSourceTypesSuccess({
                dataSourceTypes: result,
              }),
            ),
            catchError((error) =>
              of(OrganizationActions.showErrors({ message: error })),
            ),
          ),
      ),
    ),
  );

  addDataSourceTypes$ = createEffect(() =>
    this.actions$.pipe(
      ofType(OrganizationActions.addDataSourceSettings),
      withLatestFrom(
        this.store.select(selectSelectedDataSourceSettings),
        this.store.select(selectDepartmentUID),
        this.store.select(selectSelectedDataSourceorganizationUId),
      ),
      mergeMap(
        ([
          action,
          selectedDataSourceSettings,
          departmentUID,
          selectedDataSourceOrganizationUID,
        ]) =>
          this.apiService
            .addDataSourceSettings(
              selectedDataSourceOrganizationUID,
              action.dataSourceSetting,
              departmentUID,
              selectedDataSourceSettings.utilityServiceID,
            )
            .pipe(
              mergeMap(() =>
                of(
                  OrganizationActions.addDataSourceSettingsSuccess(),
                  OrganizationActions.getDataSourceSettingsList({
                    organizationUID: selectedDataSourceOrganizationUID,
                  }),
                  OrganizationActions.showSuccessMessage({
                    message: 'Created',
                  }),
                ),
              ),
              catchError((error) =>
                of(
                  OrganizationActions.validationError({
                    ValidationErrors: error,
                  }),
                ),
              ),
            ),
      ),
    ),
  );
  searchChange$ = createEffect(() =>
    this.actions$.pipe(
      ofType(OrganizationActions.searchChange),
      withLatestFrom(
        this.store.select(selectDepartmentUID),
        this.store.select(selectOrganizationPagingRequest),
      ),

      filter(
        ([action, departmentUID, pagingRequest]) =>
          departmentUID != '0' && departmentUID != '',
      ),
      map(([action, departmentUID, pagingRequest]) =>
        OrganizationActions.getOrganizationList({
          pagingRequest: {
            ...initialOrganizationState.pagingRequest,
            pageSize: pagingRequest.pageSize,
            orderBy: pagingRequest.orderBy,
            isDescending: pagingRequest.isDescending,
          },
        }),
      ),
    ),
  );

  updatedDataSourceTypes$ = createEffect(() =>
    this.actions$.pipe(
      ofType(OrganizationActions.EditDataSourceSettings),
      withLatestFrom(
        this.store.select(selectSelectedDataSourceSettings),
        this.store.select(selectDepartmentUID),
        this.store.select(selectSelectedDataSourceorganizationUId),
      ),
      mergeMap(
        ([
          action,
          selectedDataSourceSettings,
          departmentUID,
          selectedDataSourceOrganizationUID,
        ]) =>
          this.apiService
            .EditDataSourceSettings(
              selectedDataSourceOrganizationUID,
              action.dataSourceSetting,
              departmentUID,
              selectedDataSourceSettings.utilityServiceID,
            )
            .pipe(
              mergeMap(() =>
                of(
                  OrganizationActions.EditDataSourceSettingsSuccess(),
                  OrganizationActions.getDataSourceSettingsList({
                    organizationUID: selectedDataSourceOrganizationUID,
                  }),
                  OrganizationActions.showSuccessMessage({
                    message: 'Updated',
                  }),
                ),
              ),
              catchError((error) =>
                of(
                  OrganizationActions.validationError({
                    ValidationErrors: error,
                  }),
                ),
              ),
            ),
      ),
    ),
  );

  deleteDataSourceTypes$ = createEffect(() =>
    this.actions$.pipe(
      ofType(OrganizationActions.deleteDataSourceSettings),
      withLatestFrom(
        this.store.select(selectSelectedDataSourceSettings),
        this.store.select(selectDepartmentUID),
        this.store.select(selectSelectedDataSourceorganizationUId),
      ),
      mergeMap(
        ([
          action,
          selectedDataSourceSettings,
          departmentUID,
          selectedDataSourceOrganizationUID,
        ]) =>
          this.apiService
            .deleteDataSourceSettings(
              selectedDataSourceOrganizationUID,
              action.dataSourceSettingUID,
              departmentUID,
            )
            .pipe(
              mergeMap((result) =>
                of(
                  OrganizationActions.deleteDataSourceSettingsSuccess(),
                  OrganizationActions.getDataSourceSettingsList({
                    organizationUID: selectedDataSourceOrganizationUID,
                  }),
                  OrganizationActions.showSuccessMessage({
                    message: 'Data Source was deleted',
                  }),
                ),
              ),
              catchError((error) =>
                of(OrganizationActions.showErrors({ message: error })),
              ),
            ),
      ),
    ),
  );

  getChannelConfigurationsList$ = createEffect(() =>
    this.actions$.pipe(
      ofType(OrganizationActions.getChannelConfigurations),
      withLatestFrom(
        this.store.select(selectDepartmentUID),
        this.store.select(selectCurrentUserOrganizationUID),
      ),
      mergeMap(([action, departmentUID, organizationUID]) =>
        this.apiService
          .getChannelConfigurations(
            action.organizationUID === null // if we don't have organizationUID - give current.
              ? organizationUID
              : action.organizationUID,
            departmentUID,
          )
          .pipe(
            map((result) =>
              OrganizationActions.getChannelConfigurationsSuccess({
                organizationUID: action.organizationUID,
                channelConfigurationList: result,
              }),
            ),
            catchError((error) =>
              of(OrganizationActions.showErrors({ message: error })),
            ),
          ),
      ),
    ),
  );

  getCurrentChannelConfigurationsList$ = createEffect(() =>
    this.actions$.pipe(
      ofType(OrganizationActions.getCurrentChannelConfigurations),
      withLatestFrom(
        this.store.select(selectCurrentUserDepartmentUID),
        this.store.select(selectCurrentUserOrganizationUID),
      ),
      mergeMap(([action, departmentUID, organizationUID]) =>
        this.apiService
          .getChannelConfigurations(
            action.organizationUID === null // if we don't have organizationUID - give current.
              ? organizationUID
              : action.organizationUID,
            departmentUID,
          )
          .pipe(
            map((result) =>
              OrganizationActions.getChannelConfigurationsSuccess({
                organizationUID: action.organizationUID,
                channelConfigurationList: result,
              }),
            ),
            catchError((error) =>
              of(OrganizationActions.showErrors({ message: error })),
            ),
          ),
      ),
    ),
  );

  addChannelConfigurationsList$ = createEffect(() =>
    this.actions$.pipe(
      ofType(OrganizationActions.addChannelConfigurations),
      withLatestFrom(
        this.store.select(selectChannelConfigurationsOrganizationUID),
        this.store.select(selectDepartmentUID),
      ),
      mergeMap(([action, organizationUID, departmentUID]) =>
        this.apiService
          .addChannelConfigurations(
            organizationUID,
            departmentUID,
            action.channelConfiguration,
          )
          .pipe(
            mergeMap((result) =>
              of(
                OrganizationActions.addChannelConfigurationsSuccess({
                  channelConfiguration: result,
                }),
                OrganizationActions.showSuccessMessage({
                  message: 'Configuration created.',
                }),
              ),
            ),
            catchError((error) =>
              of(OrganizationActions.showErrors({ message: error })),
            ),
          ),
      ),
    ),
  );

  editChannelConfigurationsList$ = createEffect(() =>
    this.actions$.pipe(
      ofType(OrganizationActions.editChannelConfigurations),
      withLatestFrom(
        this.store.select(selectChannelConfigurationsOrganizationUID),
        this.store.select(selectDepartmentUID),
      ),
      mergeMap(([action, organizationUID, departmentUID]) =>
        this.apiService
          .editChannelConfigurations(
            organizationUID,
            departmentUID,
            action.channelConfiguration,
          )
          .pipe(
            mergeMap((result) =>
              of(
                OrganizationActions.editChannelConfigurationsSuccess({
                  channelConfiguration: action.channelConfiguration,
                }),
                OrganizationActions.showSuccessMessage({
                  message: 'Configuration updated.',
                }),
              ),
            ),
            catchError((error) =>
              of(OrganizationActions.showErrors({ message: error })),
            ),
          ),
      ),
    ),
  );

  deleteChannelConfigurationsList$ = createEffect(() =>
    this.actions$.pipe(
      ofType(OrganizationActions.deleteChannelConfigurations),
      withLatestFrom(
        this.store.select(selectChannelConfigurationsOrganizationUID),
        this.store.select(selectDepartmentUID),
      ),
      mergeMap(([action, organizationUID, departmentUID]) =>
        this.apiService
          .deleteChannelConfigurations(
            organizationUID,
            departmentUID,
            action.channelConfigurationUID,
          )
          .pipe(
            mergeMap((result) =>
              of(
                OrganizationActions.deleteChannelConfigurationsSuccess({
                  channelConfigurationUID: action.channelConfigurationUID,
                }),
                OrganizationActions.showSuccessMessage({
                  message: 'Configuration deleted.',
                }),
              ),
            ),
            catchError((error) =>
              of(OrganizationActions.showErrors({ message: error })),
            ),
          ),
      ),
    ),
  );

  showErrors$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(OrganizationActions.showErrors),
        tap((action) => {
          // this.notification.error(action.message);
        }),
      ),
    { dispatch: false },
  );

  showSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(OrganizationActions.showSuccessMessage),
        tap((action) => {
          this.notification.success(undefined, action.message);
        }),
      ),
    { dispatch: false },
  );
}
