import { IDepartment } from '@features/departments/departments-store/departments.interface';
import { IMeterGroup } from '@features/meter-group/meter-group-store/meter-group.interface';
import { IOrganization } from '@features/organizations/organizations-store/organizations.interface';
import {
  IFilterOptions,
  IPagingRequest,
  IPagingResponse,
} from '@shared/utility/global-enums';

// Interface for working with any users.

export interface IUserResult {
  items: IUser[];
  pagingInfo: IPagingResponse;
  isLoading: boolean;
}

export interface IUserState {
  usersList: IUserResult;
  pagingRequest: IPagingRequest;
  filter: {
    RoleId: Role[];
    StatusId: AccountStatus[];
    MeterGroupUID: string[];
  };
  filterOptions: IFilterOptions[];
  search: string;
  selectedUser: IUser;
  newUser: boolean;
  accessObjects: { departments: AccessObject[]; meterGroups: AccessObject[] };
  errors: { isError: boolean; errorMessage: any };
  drawersStates: {
    canCloseUserEditDrawer: boolean;
  };
}
export interface IUser {
  userUID: string;
  azureUserID: string;
  userName: string;
  firstName: string;
  lastName: string;
  emailAddress: string;
  mobilePhone: string;
  languageID: number;
  defaultPageID: number;
  userSourceID: number;
  userProfileID: UserProfile;
  loginIdentifierTypeID: number;
  accountStatusID: AccountStatus;
  accountStatusLabel?: string;
  expirationDate?: Date;
  createDate: Date;
  createuserUID: string;
  createUserName: string;
  currentDepartmentUID: string | null;
  // currentOrganizationUID: string | null;

  hasAssignedDepartment: boolean; //canEditMultiDep:boolean;
  roles: Role[];
  // AccessObject: AccessObject[];
  organizations: AccessObject[];
  meterGroupUIDs: AccessObject[];
  departmentUIDs: string[];
  allGroupsAssigned: boolean;
}

// Temporary interface for working with sending data.
export interface IPendingUser {
  emailAddress: string;
  userProfileID: UserProfile;
  expirationDate?: Date;
  isExternalAD: boolean;
  loginIdentifierTypeID: LoginIdentifierType;
  languageID: Language;
  roles: Role[];
  // accessObjects: { departments: AccessObject[]; meterGroups: AccessObject[] };
  dbUserUID: string;
}
export interface AccessObject {
  name: string;
  uid: string;
}
export interface AccessObjectList {
  list: AccessObject[];
  type: AccessObjectType;
}
export enum UserProfile {
  Regular = 1,
  Billing = 2,
  Marketing = 3,
  Support = 4,
  Tenant = 5,
}

export enum AccountStatus {
  Active = 1,
  Inactive = 2,
  Pending = 3,
  Suspended = 4,
  Locked = 5,
  Expired = 6,
  Deleted = 10,
}

export enum Role {
  SysAdmin = 1,
  PermissionsAdmin = 2,
  DepartmentAdmin = 3,
  DeviceAdmin = 5,
  DataAdmin = 6,
  Viewer = 20,
}

export const AdminRolesList = [
  Role.SysAdmin,
  Role.PermissionsAdmin,
  Role.DepartmentAdmin,
  Role.DeviceAdmin,
  Role.DataAdmin,
];

// function that creates a key(=name) value(=number) pair from the enum and removes the number
export function enum2KeyValuePairs(
  enumme: any,
): { label: string; value: number; checked: boolean }[] {
  return Object.keys(enumme)
    .filter((key) => isNaN(Number(key)))
    .map((key) => ({ label: key, value: enumme[key], checked: false }));
}

export enum LoginIdentifierType {
  UserNamePassword = 1,
  ActiveDirectory = 2,
  // Google = 3,
  // Facebook = 4,
}

export enum Language {
  English_US = 1,
}

export enum AccessObjectType {
  Department = 1,
  Organization = 2,
  Site = 3,
}
