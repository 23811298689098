import {
  initialPagingRequest,
  initialPagingResponse,
} from '@shared/utility/global-enums';
import { IOrganization, IOrganizationState } from './organizations.interface';
import { OrganizationStatus } from '@shared/utility/global-enums';

// default values added for using in the initial state
const defaultOrganisationValues: IOrganization = {
  organizationUID: '',
  organizationName: '',
  countryID: null,
  organizationTypeID: 1,
  stateID: null,
  city: '',
  zipCode: '',
  streetAddress: '',
  latitude: null,
  longitude: null,
  organizationStatusId: OrganizationStatus.Active,
  organizationMedia: {
    icon: null,
    logo: null,
  },
  daysTillSuspension: 30,
  contactFullName: '',
  contactEmail: null,
  contactPhone: '',
  // createdAt: '',
  weatherDataRequired: false,
  isProcessDailyMaxDemands: false,
  maxDaysUpdateBack: null,
  scheduleTimeZoneID: '',
  isValidationDisabled: false,
  numberOfMeters: 0,
  demandInterval: 15,
  // organizationType: {
  //   organizationTypeId: null,
  //   name: '',
  //   parentId: null,
  //   parentName: '',
  //   children: [],
  // },
  // organizationStatus: {
  //   organizationStatusId: null,
  //   organizationStatusEnum: '',
  // },
};

export const initialOrganizationState: IOrganizationState = {
  organizations: {
    pagingInfo: initialPagingResponse,
    items: [],
    isLoading: false,
  },
  departmentUID: '',
  pagingRequest: initialPagingRequest,
  filterOptions: [],
  filter: {
    StatusId: [],
    CountryId: [],
    WeatherDataRequired: [],
  },
  search: '',
  selectedOrganization: defaultOrganisationValues,
  selectedRootOrganization: defaultOrganisationValues,
  organizationTypes: [],
  newOrganization: false,
  organizationsRequestList: {
    organizationUId: '',
    scheduleTimeZoneID: '',
    organizationRequests: [
      { requestTypeID: 1, isEnabled: false, frequency: null, startFrom: null },
    ],
  },
  organizationChannelConfigurationList: {
    organizationUID: '',
    channelConfigurationsList: [],
  },
  selectedChannelConfigurations: {
    channelConfigurationUID: null,
    channelConfigurationName: '',
    mappings: [],
  },
  organizationApiCredentials: {
    organizationUID: '',
    apiCredentials: null,
  },
  dataSourcesSettingsList: {
    organizationUId: '',
    dataSourceTypes: [],
    dataSourceSettings: [],
  },
  selectedDataSourceSettings: {
    utilityServiceID: null,
    settings: {
      dataSourceSettingUID: '',
      sourceTypeID: null,
      enabled: false,
      parameters: [],
    },
    isDataSourceSettingsNew: null,
    usedDataSourceTypes: null,
  },
  drawersState: {
    organizationEditDrawer: false,
    dataSourceSettingsDrawer: false,
    requestEditDrawer: false,
    apiCredentialsSettingsDrawer: false,
    uploadOrganizationIconPopupVisible: false,
    channelConfigurationDrawer: false,
  },
  ValidationErrors: null,
  lastProcessing: null,
};
