import {
  Component,
  OnInit,
  AfterViewInit,
  Input,
  OnChanges,
  SimpleChanges,
  EventEmitter,
  Output,
} from '@angular/core';
import { FlatTreeControl } from '@angular/cdk/tree';
import { NzTreeFlatDataSource, NzTreeFlattener } from 'ng-zorro-antd/tree-view';
import {
  GroupType,
  IMeterGroupView,
} from '@features/meter-group/meter-group-store/meter-group.interface';

@Component({
  selector: 'xpw-tree-view-meter-groups',
  templateUrl: './tree-view.component.html',
  styleUrls: ['./tree-view.component.less'], // Referencing the external .less file
})
export class XpwTreeViewComponent implements OnInit, AfterViewInit, OnChanges {
  @Input() treeData: IMeterGroupView[] = [];
  @Input() expandRows: boolean = false;
  @Input() expandAndScrollTo: string = ''; // get meterGroupUID
  @Output() createChildMeterGroup = new EventEmitter<string>();
  @Output() editMeterGroup = new EventEmitter<string>();
  @Output() deleteMeterGroup = new EventEmitter<string>();

  private transformer = (node: IMeterGroupView, level: number): FlatNode => ({
    uid: node.meterGroupUID,
    expandable: node.groupType === GroupType.Parent,
    name: node.meterGroupName,
    level,
    isLeaf: node.groupType === GroupType.Meter,
    createDate: node.createDate,
    meterCount: node.meterUIDs.length,
    usersCount: node.usersCount,
    selected: node.selected,
  });

  treeControl = new FlatTreeControl<FlatNode>(
    (node) => node.level,
    (node) => node.expandable,
  );

  treeFlattener = new NzTreeFlattener(
    this.transformer,
    (node) => node.level,
    (node) => node.expandable,
    (node) => node.children,
  );

  dataSource = new NzTreeFlatDataSource(this.treeControl, this.treeFlattener);

  showLeafIcon = true;

  constructor() {}

  hasChild = (_: number, node: FlatNode): boolean => node.expandable;

  ngOnInit() {
    // Set the tree data once the component is initialized
    this.dataSource.setData(this.treeData);
  }

  ngAfterViewInit(): void {
    if (this.expandRows) {
      this.treeControl.expandAll();
    }
    if (this.expandAndScrollTo) {
      this.expandAndScrollToNode(this.expandAndScrollTo);
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    // Check if the input treeData has changed
    if (changes['treeData'] && !changes['treeData'].isFirstChange()) {
      // Update the data source with the new treeData
      this.dataSource.setData(this.treeData);
    }

    if (this.expandRows) {
      this.treeControl.expandAll();
    }

    this.expandAndScrollToNode(this.expandAndScrollTo);
  }

  /**
   * Expands the parent nodes of the node and scrolls to the target node
   */
  expandAndScrollToNode(uid: string): void {
    const nodeToExpand = this.treeControl.dataNodes.find(
      (node) => node.uid === uid,
    );

    nodeToExpand.selected = true;

    if (nodeToExpand) {
      // Expand the parent nodes
      this.expandParents(nodeToExpand);

      // Scroll to the node after a short delay (ensures DOM updates)
      setTimeout(() => {
        const element = document.getElementById(`node-${uid}`);
        if (element) {
          element.scrollIntoView({ behavior: 'smooth', block: 'center' });
        }
      }, 200);
    }
  }

  /**
   * Recursively expands the parent nodes of the current node
   */
  expandParents(node: FlatNode): void {
    let currentNode = node;
    while (currentNode && currentNode.level > 0) {
      const parent = this.getParentNode(currentNode);
      if (parent) {
        this.treeControl.expand(parent);
        currentNode = parent;
      } else {
        break;
      }
    }
  }

  /**
   * Finds the parent node of the given node
   */
  getParentNode(node: FlatNode): FlatNode | null {
    const currentIndex = this.treeControl.dataNodes.indexOf(node);
    for (let i = currentIndex - 1; i >= 0; i--) {
      if (this.treeControl.dataNodes[i].level < node.level) {
        return this.treeControl.dataNodes[i];
      }
    }
    return null;
  }

  /**
   * Toggles the expansion of a node
   */
  toggleNode(node: FlatNode): void {
    this.treeControl.toggle(node);
  }

  /**
   * Emits an event to create a child node
   */
  createChildNode(node: FlatNode): void {
    this.createChildMeterGroup.emit(node.uid);
  }

  /**
   * Emits an event to edit a node
   */
  editNode(node: FlatNode): void {
    this.editMeterGroup.emit(node.uid);
  }

  /**
   * Emits an event to delete a node
   */
  deleteNode(node: FlatNode): void {
    this.deleteMeterGroup.emit(node.uid);
  }

  /**
   * Sorts the tree data based on the field and order
   */
  changedOrder(fieldsName: string, order: string) {
    const sortGroups = (groups: IMeterGroupView[]): IMeterGroupView[] => {
      const sortedGroups = [...groups].sort((a, b) => {
        let valueA = a[fieldsName];
        let valueB = b[fieldsName];

        if (valueA === null || valueA === undefined)
          return order === 'abc' ? 1 : -1;
        if (valueB === null || valueB === undefined)
          return order === 'abc' ? -1 : 1;

        if (valueA instanceof Date && valueB instanceof Date) {
          return order === 'abc'
            ? valueA.getTime() - valueB.getTime()
            : valueB.getTime() - valueA.getTime();
        } else if (typeof valueA === 'string' && typeof valueB === 'string') {
          return order === 'abc'
            ? valueA.localeCompare(valueB)
            : valueB.localeCompare(valueA);
        } else if (typeof valueA === 'number' && typeof valueB === 'number') {
          return order === 'abc' ? valueA - valueB : valueB - valueA;
        }
        return 0; // No sorting if types do not match
      });

      // Recursively sort child groups if they exist
      return sortedGroups.map((group) => ({
        ...group,
        children: group.children ? sortGroups(group.children) : [],
      }));
    };

    const sortedTreeData = sortGroups(this.treeData);

    // Update the data source with the sorted data
    this.treeData = [...sortedTreeData]; // Assign a new array reference to trigger change detection
    this.dataSource.setData(this.treeData);
  }

  /**
   * Counts the total number of groups including their children
   */
  countFilteredGroups = (groups: IMeterGroupView[]): number => {
    return groups.reduce((count, group) => {
      return count + 1 + this.countFilteredGroups(group.children);
    }, 0);
  };
}

interface FlatNode {
  expandable: boolean;
  name: string;
  uid: string;
  level: number;
  isLeaf: boolean;
  selected: boolean;
  createDate: Date;
  meterCount: number;
  usersCount: number;
}
