/// <reference types="@angular/localize" />

import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';

import { provideRouter } from '@angular/router';
import { provideStore } from '@ngrx/store';
import { provideRouterStore, routerReducer } from '@ngrx/router-store';
// import { init, instrumentAngularRouting, BrowserTracing } from '@sentry/angular-ivy';

/* init({
  dsn: 'https://4f17f6e564f04d78a755ea367c5b8eb0@o1187069.ingest.sentry.io/6307023',
  integrations: [
    new BrowserTracing({
      tracingOrigins: [
        'localhost',
        'https://devapi.expertpower.com/swagger/index.html',
        'https://localhost:4200/',
      ],
      routingInstrumentation: instrumentAngularRouting,
    }),
  ],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,
});
 */
platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .catch((err) => console.error(err));
