import { Component, Input, OnInit } from '@angular/core';
import { AccountStatus } from '@features/user/user-store/user.interface';

@Component({
  selector: 'xpw-account-status-tag',
  template: `
    <nz-tag [nzColor]="color">
      <xpw-icon icon="{{ icon }}" />
      <span>{{ stringTitle }}</span>
    </nz-tag>
  `,
})
export class XpwAccountStatusTagComponent implements OnInit {
  @Input() status: AccountStatus = AccountStatus.Active;
  stringTitle: string = '';
  icon: string | null = null;
  color: string = 'default';
  constructor() {}

  ngOnInit() {
    switch (this.status) {
      case AccountStatus.Active:
        this.stringTitle = $localize`Active`;
        this.icon = 'check';
        this.color = 'green';
        break;
      case AccountStatus.Inactive:
        this.stringTitle = $localize`Inactive`;
        break;
      case AccountStatus.Pending:
        this.stringTitle = $localize`Pending`;
        this.icon = 'clock-circle';
        break;
      case AccountStatus.Suspended:
        this.stringTitle = $localize`Suspended`;
        break;
      case AccountStatus.Locked:
        this.stringTitle = $localize`Locked`;
        this.icon = 'locked';
        break;
      case AccountStatus.Expired:
        this.stringTitle = $localize`Expired`;
        this.icon = 'expired';
        this.color = 'red';
        break;
      case AccountStatus.Deleted:
        this.stringTitle = $localize`Deleted`;
        break;
      default:
        this.stringTitle = $localize`Unknown`;
    }
  }
}
