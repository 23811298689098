import { Component, Input, forwardRef } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { AbstractInputComponent } from '../abstract-input.component';
import { NzTSType } from 'ng-zorro-antd/core/types';

@Component({
  selector: 'xpw-form-input-text',
  template: `
    <nz-form-item>
      <nz-form-label
        >{{ label }} {{ isOptional ? ' (Optional)' : '' }}
      </nz-form-label>
      <nz-form-control
        [nzHasFeedback]="nzHasFeedback"
        [nzValidateStatus]="errorStatus()"
        [nzErrorTip]="getErrorLabel()"
      >
        <nz-input-group [nzPrefixIcon]="icon ? 'xpw-outline:' + icon : null">
          <input
            type="text"
            nz-input
            [value]="value"
            [placeholder]="placeholder"
            [(ngModel)]="value"
            [disabled]="readonly"
          />
        </nz-input-group>
      </nz-form-control>
    </nz-form-item>
  `,
  styles: [
    `
      .ant-form-vertical nz-form-label.ant-form-item-label {
        padding: 0 0 4px 0;
      }
    `,
  ],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => XpwInputComponent),
      multi: true,
    },
  ],
})
export class XpwInputComponent extends AbstractInputComponent {
  @Input('label') label: string;
  @Input('icon') icon: string;
  @Input('hasFeedback') nzHasFeedback: boolean = true;
}
