import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import {
  of,
  map,
  debounceTime,
  distinctUntilChanged,
  switchMap,
  Subject,
} from 'rxjs';

@Component({
  selector: 'xpw-search-input',
  template: `
    <nz-input-group [nzSuffix]="suffixTemplateInfo" [nzSize]="'large'">
      <input
        type="text"
        nz-input
        i18n-placeholder
        placeholder="Search"
        (input)="onChange()"
        [(ngModel)]="searchText"
      />
    </nz-input-group>
    <ng-template #suffixTemplateInfo>
      @if (searchText) {
        <span
          nz-icon
          nz-tooltip
          nzTooltipTitle="delete search"
          nzType="xpw-outline:clean-search"
          (click)="searchText = ''; onChange()"
        ></span>
      } @else {
        <span
          nz-icon
          nz-tooltip
          nzTooltipTitle="type to search..."
          nzType="xpw-outline:search"
        ></span>
      }
    </ng-template>
  `,
  styleUrls: ['./xpw-search-input.component.less'],
})
export class XpwSearchInputComponent implements OnInit {
  @Output() searchFieldChanged: EventEmitter<string> =
    new EventEmitter<string>();
  searchText: string = '';
  keyPress$ = new Subject();
  ngOnInit() {
    this.keyPress$
      .pipe(
        // wait 1 second to start
        debounceTime(1000),
        // if value is the same, ignore
        distinctUntilChanged(),
        // start connection
        switchMap(
          (term) => {
            return of(term);
          },
          //     this.autocompleteService.search({
          //     // term: this.inputBox.nativeElement.value
          //   })
        ),
      )
      .subscribe((result: any) => {
        console.log('result', result);
        this.searchFieldChanged.emit(result);
      });
  }
  onChange() {
    this.keyPress$.next(this.searchText);
  }
}
