// src/app/MeterGroup/state/MeterGroup.effects.ts

import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, mergeMap, tap, withLatestFrom } from 'rxjs/operators';
import * as MeterGroupActions from './meter-group.actions';
import { MeterGroupsService } from '@core/api/meter-group.service';
import { NotificationService } from '@core/services/notifications/notifications.service';
import {
  getAccessObjects,
  updateAccessObjects,
  UserActions,
} from 'src/app/store/current-user/current-user.actions';
import { selectMeterNameList } from '@features/meters/meters-store/meters.selector';
import { Store } from '@ngrx/store';
import { selectSelectedMeterGroup } from './meter-group.selector';

@Injectable()
export class MeterGroupEffects {
  constructor(
    private actions$: Actions,
    private apiService: MeterGroupsService,
    private notification: NotificationService,
    private store: Store,
  ) {}

  getMeterGroupList$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MeterGroupActions.getMeterGroupList),
      mergeMap((action) =>
        this.apiService.getMeterGroups().pipe(
          mergeMap((meterGroups) =>
            of(
              MeterGroupActions.getMeterGroupListSuccess({ meterGroups }),
              updateAccessObjects({
                departments: null,
                meterGroups: meterGroups,
              }),
            ),
          ),
          catchError((error) =>
            of(MeterGroupActions.showErrors({ message: error })),
          ),
        ),
      ),
    ),
  );

  getMetersList$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MeterGroupActions.selectMeterGroup),
      mergeMap((action) =>
        this.apiService.getMetersList(action.meterGroupUID).pipe(
          map((meterUIDs) =>
            MeterGroupActions.getMetersListSuccess({ meterUIDs }),
          ),
          catchError((error) =>
            of(MeterGroupActions.showErrors({ message: error })),
          ),
        ),
      ),
    ),
  );

  setUpdateMeterGroup$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MeterGroupActions.setUpdateMeterGroup),
      mergeMap((action) =>
        this.apiService.updateMeterGroup(action.MeterGroupUpdated).pipe(
          mergeMap(() =>
            of(
              MeterGroupActions.showMetersGroupSuccessMessage({
                message: 'Meter Group updated',
              }),
              MeterGroupActions.setUpdateMeterGroupSuccess(),
              MeterGroupActions.setLastChangedCreatedMeterGroup({
                meterGroup: action.MeterGroupUpdated,
              }),
              MeterGroupActions.getMeterGroupList(),
            ),
          ),
          catchError((ValidationErrors) =>
            of(MeterGroupActions.validationError({ ValidationErrors })),
          ),
        ),
      ),
    ),
  );

  setNewMeterGroup$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MeterGroupActions.setNewMeterGroup),
      mergeMap((action) =>
        this.apiService.addMeterGroup(action.MeterGroupUpdated).pipe(
          mergeMap((result) =>
            of(
              MeterGroupActions.showMetersGroupSuccessMessage({
                message: 'Meter Group created',
              }),
              MeterGroupActions.setNewMeterGroupSuccess(),
              MeterGroupActions.setLastChangedCreatedMeterGroup({
                meterGroup: {
                  ...action.MeterGroupUpdated,
                  meterGroupUID: result,
                },
              }),
              MeterGroupActions.getMeterGroupList(),
            ),
          ),
          catchError((ValidationErrors) =>
            of(MeterGroupActions.validationError({ ValidationErrors })),
          ),
        ),
      ),
    ),
  );

  deleteMeterGroup$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MeterGroupActions.deleteMeterGroup),
      mergeMap((action) =>
        this.apiService.deleteMeterGroup(action.meterGroupUID).pipe(
          mergeMap(() =>
            of(
              MeterGroupActions.showMetersGroupSuccessMessage({
                message: 'Meter Group deleted',
              }),
              MeterGroupActions.getMeterGroupList(),
            ),
          ),
          catchError((error) =>
            of(MeterGroupActions.showErrors({ message: error })),
          ),
        ),
      ),
    ),
  );

  showErrors$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(MeterGroupActions.showErrors),
        tap((action) => {
          // this.notification.error(action.message);
        }),
      ),
    { dispatch: false },
  );

  showSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(MeterGroupActions.showMetersGroupSuccessMessage),
        tap((action) => {
          this.notification.success(undefined, action.message);
        }),
      ),
    { dispatch: false },
  );

  selectMeterByName$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MeterGroupActions.selectMeterByName),
      withLatestFrom(this.store.select(selectMeterNameList)),
      map(([action, meterNameList]) => {
        // Filter the meters based on the action's payload
        const filteredMeters = meterNameList.filter((meter) =>
          meter.name.includes(action.name),
        );
        // Dispatch a new action with the filtered UIDs
        return MeterGroupActions.updateSelectedMeterUIDs({
          meterUIDs: filteredMeters.map((meter) => meter.uid),
        });
      }),
    ),
  );

  unselectMeterByName$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MeterGroupActions.unselectMeterByName),
      withLatestFrom(
        this.store.select(selectMeterNameList),
        this.store.select(selectSelectedMeterGroup),
      ), // Get the current selected meter UIDs
      map(([action, meterNameList, meterGroup]) => {
        // Find the UIDs of meters that match the name substring
        const filteredMeters = meterNameList.filter((meter) =>
          meter.name.includes(action.name),
        );
        const uidsToUnselect = filteredMeters.map((meter) => meter.uid);
        // Create a new list of UIDs by removing the ones to unselect
        const updatedUIDs = meterGroup.meterUIDs.filter(
          (uid) => !uidsToUnselect.includes(uid),
        );

        // Dispatch a new action to update the UIDs
        return MeterGroupActions.updateSelectedMeterUIDs({
          meterUIDs: updatedUIDs,
        });
      }),
    ),
  );
}
