import { createReducer, on } from '@ngrx/store';
import * as DepartmentActions from './departments.actions';
import { initialDepartmentState } from './departments.state';

// IDepartmentState interface
export const DepartmentReducer = createReducer(
  initialDepartmentState,
  on(DepartmentActions.selectDepartment, (state, { DepartmentUID }) => ({
    ...state,
    newDepartment: false,
    successResult: false,
    selectedDepartment:
      state.departments.find((item) => item.departmentUID === DepartmentUID) ||
      null,
  })),
  on(DepartmentActions.createNewDepartment, (state) => ({
    ...state,
    newDepartment: true,
    successResult: false,
    selectedDepartment: initialDepartmentState.selectedDepartment,
  })),
  on(DepartmentActions.getDepartmentListSuccess, (state, { Departments }) => ({
    ...state,
    departments: Departments,
    filteredDepartments: Departments,
  })),
  on(DepartmentActions.getStorageListSuccess, (state, { storageList }) => ({
    ...state,
    storageList: storageList,
  })),
  on(DepartmentActions.setUpdateDepartmentSuccess, (state) => ({
    ...initialDepartmentState,
    successResult: true,
  })),
  on(DepartmentActions.setNewDepartmentSuccess, (state) => ({
    ...initialDepartmentState,
    successResult: true,
  })),
  on(
    DepartmentActions.setUpdateDepartmentFailure,
    (state, { ValidationErrors }) => {
      return {
        ...state,
        ValidationErrors,
      };
    },
  ),
  on(
    DepartmentActions.setNewDepartmentFailure,
    (state, { ValidationErrors }) => {
      return {
        ...state,
        ValidationErrors,
      };
    },
  ),
  on(DepartmentActions.resetDepartmentState, (state) => ({
    ...initialDepartmentState,
  })),
  on(DepartmentActions.searchChange, (state, { search }) => ({
    ...state,
    searchText: search,
    filteredDepartments: state.departments.filter(
      (item) =>
        item.departmentName.toUpperCase().includes(search.toUpperCase()) ||
        item.storageName.toUpperCase().includes(search.toUpperCase()),
    ),
  })),
);
