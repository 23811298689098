import { Injectable } from '@angular/core';

import { LogPublisher } from './log-publishers';
import { LogPublishersService } from './log-publishers.service';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { environment } from 'src/environments/environment';
@Injectable({
  providedIn: 'root',
})
export class LoggerService {
  level: LogLevel = LogLevel.All;
  logWithDate: boolean = true;
  publishers: LogPublisher[];

  appInsights: ApplicationInsights;
  constructor(private publisherService: LogPublishersService) {
    this.publishers = this.publisherService.publishers;

    this.appInsights = new ApplicationInsights({
      config: {
        instrumentationKey: environment.appInsights.instrumentationKey,
        enableAutoRouteTracking: true, // option to log all route changes
      },
    });
    this.appInsights.loadAppInsights();
  }

  debug(msg: string, ...optionalParams: any[]) {
    this.writeToLog(msg, LogLevel.Debug, optionalParams);
  }

  info(msg: string, ...optionalParams: any[]) {
    this.writeToLog(msg, LogLevel.Info, optionalParams);
  }

  warn(msg: string, ...optionalParams: any[]) {
    this.writeToLog(msg, LogLevel.Warn, optionalParams);
    console.log(`%c  ${msg}`, 'color: red; font-size: 20px');
    this.appInsights.trackEvent({ name: msg });
  }

  error(msg: string, ...optionalParams: any[]) {
    this.writeToLog(msg, LogLevel.Error, optionalParams);
  }

  fatal(msg: string, ...optionalParams: any[]) {
    this.writeToLog(msg, LogLevel.Fatal, optionalParams);
  }

  log(msg: string, ...optionalParams: any[]) {
    this.writeToLog(msg, LogLevel.All, optionalParams);
  }
  logException(exception: Error, severityLevel: number = 3, message: string = undefined) {
    this.appInsights.trackException({
      exception: exception,
      severityLevel: severityLevel,
      properties: {
        stack: exception.stack,
        message,
      },
    });
  }
  private writeToLog(msg: string, level: LogLevel, params: any[]) {
    if (this.canLog(level)) {
      let entry: LogEntry = new LogEntry();
      entry.message = msg;
      entry.level = level;
      // adding some user params (browser, os etc...)
      params = [
        params,
        {
          'window.navigator.userAgent': window.navigator.userAgent,
          'window.navigator.platform': window.navigator.platform,
        },
      ];
      entry.extraInfo = params;
      entry.logWithDate = this.logWithDate;

      for (let logger of this.publishers) {
        logger.log(entry).subscribe(); //(response:boolean) => console.log('Logged:',response)
      }
    }
  }

  private canLog(level: LogLevel): boolean {
    let ret: boolean = false;
    if (
      (level >= this.level && level !== LogLevel.Off) ||
      this.level === LogLevel.All
    ) {
      ret = true;
    }
    return ret;
  }
}

export class LogEntry {
  // Public Properties
  entryDate: Date = new Date();
  message: string = '';
  level: LogLevel = LogLevel.Debug;
  extraInfo: any[] = [];
  logWithDate: boolean = true;

  buildLogString(): string {
    let ret: string = '';

    if (this.logWithDate) {
      ret = `%c[${new Date()}]`;
    }

    ret += `| %c[Type: ${LogLevel[this.level]}] | %c[Message: ${this.message}]`;

    if (this.extraInfo.length) {
      ret += `| %c[Extra Info:  ${this.formatParams(this.extraInfo)}]`;
    }

    return ret;
  }

  private formatParams(params: any[]): string {
    let ret: string = params.join(',');

    // Is there at least one object in the array?
    if (params.some((p) => typeof p == 'object')) {
      ret = '';

      // Build comma-delimited string
      for (let item of params) {
        ret += JSON.stringify(item) + ',';
      }
    }

    return ret;
  }
}

enum LogLevel {
  All = 0,
  Debug = 1,
  Info = 2,
  Warn = 3,
  Error = 4,
  Fatal = 5,
  Off = 6,
}
