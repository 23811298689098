import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { NotFoundComponent } from '@core/components/not-found/not-found.component';
import { MsalGuard } from '@azure/msal-angular';
import { BrowserUtils } from '@azure/msal-browser';
import { ActUserComponent } from '@features/act-user/act-user-component/act-user.component';
import { StoreRouterConnectingModule } from '@ngrx/router-store';
import { LayoutComponent } from './layout.component';
import { isloaded } from '@core/guards/is-loaded.guard';
import { dashboardTypeGuard } from '@core/guards/dashboard-type.guard';
import { redirectToDefaultPage } from '@core/guards/redirect-to-default-page.guard';
import { roleGuard } from '@core/guards/permissions-check.guard';
import { Role } from '@features/user/user-store/user.interface';

const routes: Routes = [
  {
    path: '',
    component: LayoutComponent,
    canActivate: [MsalGuard, isloaded],
    children: [
      {
        path: '',
        title: 'ExpertPower - Home page',
        redirectTo: 'dashboard/energy-overview',
        canLoad: [
          roleGuard(
            Role.SysAdmin,
            Role.DeviceAdmin,
            Role.DepartmentAdmin,
            Role.DataAdmin,
            Role.PermissionsAdmin,
            Role.Viewer,
          ),
        ],
        pathMatch: 'full',
      },
      {
        path: 'electricity',
        title: 'ExpertPower - Test page',
        redirectTo: 'electricity/test',
      },
      {
        path: 'electricity/test',
        title: 'ExpertPower - test page',
        loadChildren: () =>
          import('./features/test-page/test.module').then((m) => m.TestModule),
      },
      {
        path: 'user-profile',
        redirectTo: 'user-profile/current-organization',
        pathMatch: 'full',
        title: 'ExpertPower - user-profile',
      },
      {
        path: 'user-profile/current-organization',
        pathMatch: 'full',
        title: 'ExpertPower - user-profile',
        loadChildren: () =>
          import(
            './features/current-user-profile/current-user-profile.module'
          ).then((m) => m.CurrentUserProfileModule),
      },
      {
        path: 'dashboard/:dashboardType',
        title: 'ExpertPower - Dashboard',
        canActivate: [dashboardTypeGuard],
        loadChildren: () =>
          import('./features/dashboard/dashboard.module').then(
            (m) => m.DashboardModule,
          ),
      },
      {
        path: 'permissions/user-list',
        title: 'ExpertPower - test-two page',
        canLoad: [
          roleGuard(Role.SysAdmin, Role.DepartmentAdmin, Role.PermissionsAdmin),
        ],
        loadChildren: () =>
          import('./features/user/user.module').then((m) => m.UserModule),
      },
      {
        path: 'departments/departments-list',
        title: 'List of departments',
        canLoad: [roleGuard(Role.SysAdmin)],
        loadChildren: () =>
          import('./features/departments/departments.module').then(
            (m) => m.DepartmentsModule,
          ),
      },
      {
        path: 'organizations/organization-list',
        title: 'List of organizations',
        canLoad: [roleGuard(Role.SysAdmin, Role.DepartmentAdmin)],
        loadChildren: () =>
          import('./features/organizations/organizations.module').then(
            (m) => m.OrganizationsModule,
          ),
      },
      {
        path: 'meterGroups/meter-group-list',
        title: 'List of meters group',
        canLoad: [
          roleGuard(Role.SysAdmin, Role.DepartmentAdmin, Role.DeviceAdmin),
        ],
        loadChildren: () =>
          import('./features/meter-group/meter-group.module').then(
            (m) => m.MeterGroupsModule,
          ),
      },
      {
        path: 'meters/meter-list',
        title: 'List of meters',
        canLoad: [
          roleGuard(Role.SysAdmin, Role.DepartmentAdmin, Role.DeviceAdmin),
        ],
        loadChildren: () =>
          import('./features/meters/meters.module').then((m) => m.MetersModule),
      },
      {
        path: 'importHistory/import-history-list',
        title: 'List of imported files',
        loadChildren: () =>
          import('./features/import-history/import-history.module').then(
            (m) => m.ImportHistoryModule,
          ),
      },
      {
        path: '404',
        title: 'ExpertPower - 404',
        component: NotFoundComponent,
      },
    ],
  },
  {
    path: 'register-user',
    title: 'Activation page',
    canActivate: [redirectToDefaultPage],
    pathMatch: 'full',
    component: ActUserComponent,
    loadChildren: () =>
      import('./features/act-user/act-user.module').then(
        (m) => m.ActUserModule,
      ),
  },
  { path: '**', redirectTo: '404' },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      // Don't perform initial navigation in iframes or popups
      initialNavigation:
        !BrowserUtils.isInIframe() && !BrowserUtils.isInPopup()
          ? 'enabledNonBlocking'
          : 'disabled', // Set to enabledBlocking to use Angular Universal
    }),
    StoreRouterConnectingModule.forRoot(),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
