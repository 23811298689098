import { createReducer, on } from '@ngrx/store';
import * as OrganizationActions from './organizations.actions';
import { initialOrganizationState } from './organizations.state';

export const OrganizationReducer = createReducer(
  initialOrganizationState,

  on(OrganizationActions.setDepartmentUID, (state, { departmentUID }) => ({
    ...state,
    departmentUID: departmentUID,
    filter: { ...initialOrganizationState.filter },
  })),

  on(OrganizationActions.filterChange, (state, { filter }) => ({
    ...state,
    filter: {
      StatusId: filter.StatusId,
      CountryId: filter.CountryId,
      WeatherDataRequired: filter.WeatherDataRequired,
    },
  })),
  on(
    OrganizationActions.GetOrganizationsFiltersSuccess,
    (state, { filterOptions }) => ({
      ...state,
      filterOptions,
    }),
  ),
  on(OrganizationActions.UpdateOrganizationSuccess, (state) => ({
    ...state,
    drawersState: { ...state.drawersState, organizationEditDrawer: true },
  })),
  on(OrganizationActions.createNewOrganizationSuccess, (state) => ({
    ...state,
    drawersState: { ...state.drawersState, organizationEditDrawer: true },
  })),
  on(
    OrganizationActions.GetOrganizationsFiltersSuccess,
    (state, { filterOptions }) => ({
      ...state,
      filterOptions,
    }),
  ),
  on(OrganizationActions.getOrganization, (state, { organizationUID }) => ({
    ...state,
    newOrganization: false,
    selectedOrganization: state.organizations.items.find(
      (org) => org.organizationUID === organizationUID,
    ),
  })),
  on(OrganizationActions.createNewOrganization, (state) => ({
    ...state,
    newOrganization: true,
    selectedOrganization: initialOrganizationState.selectedOrganization,
    drawersState: { ...state.drawersState, organizationEditDrawer: false },
  })),
  on(OrganizationActions.searchChange, (state, { search }) => ({
    ...state,
    search,
  })),
  // on(
  //   OrganizationActions.selectRootOrganization,
  //   (state, { organizationUID }) => ({
  //     ...state,
  //     newOrganization: false,
  //     selectedRootOrganization: state.organizations.items.find(
  //       (org) => org.organizationUID === organizationUID,
  //     ),
  //   }),
  // ),
  // on(
  //   OrganizationActions.selectRootOrganizationSuccess,
  //   (state, { organization }) => ({
  //     ...state,
  //     selectedOrganization: organization,
  //   }),
  // ),
  on(OrganizationActions.getOrganizationSuccess, (state, { organization }) => ({
    ...state,
    selectedOrganization: organization,
    drawersState: { ...state.drawersState, organizationEditDrawer: false },
  })),
  on(OrganizationActions.getOrganizationList, (state, { pagingRequest }) => ({
    ...state,
    organizations: { ...state.organizations, items: [], isLoading: true },
    pagingRequest: pagingRequest,
  })),
  on(
    OrganizationActions.getOrganizationListSuccess,
    (state, { organizations, organizationTypes }) => ({
      ...state,
      organizations: {
        items: organizations.items,
        pagingInfo: organizations.pagingInfo,
        isLoading: false,
      },
      organizationTypes: organizationTypes,
    }),
  ),
  on(
    OrganizationActions.getOrganizationApiCredentialsSuccess,
    (state, { organizationUID, orgApiCredentials }) => ({
      ...state,
      organizationApiCredentials: {
        organizationUID,
        apiCredentials: orgApiCredentials,
      },
      drawersState: {
        ...state.drawersState,
        apiCredentialsSettingsDrawer: false,
      },
    }),
  ),
  on(
    OrganizationActions.getOrganizationRequestsSuccess,
    (state, { organizationUID, orgRequestList }) => ({
      ...state,
      organizationsRequestList: {
        organizationUId: organizationUID,
        scheduleTimeZoneID: state.organizations.items.find(
          (item) => item.organizationUID === organizationUID,
        ).scheduleTimeZoneID,
        organizationRequests: orgRequestList,
      },
      drawersState: {
        ...state.drawersState,
        requestEditDrawer: false,
      },
    }),
  ),
  on(
    OrganizationActions.updateOrganizationRequests,
    (state, { organizationUID, organizationRequests }) => ({
      ...state,
      organizationsRequestList: {
        organizationUId: organizationUID,
        scheduleTimeZoneID:
          state.organizations.items.find(
            (item) => item.organizationUID === organizationUID,
          )?.scheduleTimeZoneID || '',
        organizationRequests: organizationRequests,
      },
      drawersState: {
        ...state.drawersState,
        requestEditDrawer: false,
      },
    }),
  ),
  on(OrganizationActions.updateOrganizationRequestsSuccess, (state) => ({
    ...state,
    drawersState: {
      ...state.drawersState,
      requestEditDrawer: true,
    },
  })),
  on(OrganizationActions.updateOrganizationApiCredentialsSuccess, (state) => ({
    ...state,
    drawersState: {
      ...state.drawersState,
      apiCredentialsSettingsDrawer: true,
    },
  })),
  on(
    OrganizationActions.getOrganizationTypesSuccess,
    (state, { organizationTypes }) => ({
      ...state,
      organizationTypes: organizationTypes,
    }),
  ),
  on(
    OrganizationActions.getDataSourceSettingsListSuccess,
    (state, { organizationUID, dataSourceSettingsList }) => ({
      ...state,
      dataSourcesSettingsList: {
        dataSourceTypes: state.dataSourcesSettingsList.dataSourceTypes,
        organizationUId: organizationUID,
        dataSourceSettings: dataSourceSettingsList,
        selectedDataSourceSettings: null,
        isDataSourceSettingsNew: null,
      },
    }),
  ),

  on(OrganizationActions.SetNewDataSourceSettings, (state, actions) => ({
    ...state,

    selectedDataSourceSettings: {
      ...initialOrganizationState.selectedDataSourceSettings,
      utilityServiceID: actions.utilityServiceID,
      isDataSourceSettingsNew: true,
      usedDataSourceTypes: state.dataSourcesSettingsList.dataSourceSettings[
        actions.utilityServiceID - 1
      ].dataSources.map((dataSourceTypes) => dataSourceTypes.sourceTypeID),
    },
    drawersState: {
      ...state.drawersState,
      dataSourceSettingsDrawer: false,
    },
  })),
  on(OrganizationActions.showIconUploadPopup, (state, actions) => ({
    ...state,
    drawersState: {
      ...state.drawersState,
      uploadOrganizationIconPopupVisible: true,
    },
  })),
  on(OrganizationActions.putIconToSelectedOrganization, (state, actions) => ({
    ...state,
    selectedOrganization: {
      ...state.selectedOrganization,
      organizationMedia: {
        icon: actions.iconImage,
        logo: null,
      },
    },
  })),
  on(OrganizationActions.deleteOrganizationIcon, (state, actions) => ({
    ...state,
    selectedOrganization: {
      ...state.selectedOrganization,
      organizationMedia: {
        icon: null,
        logo: null,
      },
    },
  })),
  on(OrganizationActions.closeIconUploadPopup, (state, actions) => ({
    ...state,
    drawersState: {
      ...state.drawersState,
      uploadOrganizationIconPopupVisible: false,
    },
  })),
  on(OrganizationActions.addDataSourceSettingsSuccess, (state) => ({
    ...state,
    drawersState: {
      ...state.drawersState,
      dataSourceSettingsDrawer: true,
    },
  })),
  on(OrganizationActions.EditDataSourceSettingsSuccess, (state) => ({
    ...state,
    drawersState: {
      ...state.drawersState,
      dataSourceSettingsDrawer: true,
    },
  })),
  on(OrganizationActions.GetLastProcessingSuccess, (state, action) => ({
    ...state,
    lastProcessing: action.lastProcessing,
  })),
  on(
    OrganizationActions.SetEditDataSourceSettings,
    (state, { utilityServiceID, dataSourceSettingUID }) => {
      const dataSourceSettings =
        state.dataSourcesSettingsList.dataSourceSettings[utilityServiceID - 1]
          .dataSources;
      const selectedSettings =
        dataSourceSettings.find(
          (value) => value.dataSourceSettingUID === dataSourceSettingUID,
        ) || null;

      const usedDataSourceTypes = dataSourceSettings
        .filter(
          (dataSource) =>
            dataSource.sourceTypeID !== selectedSettings.sourceTypeID,
        )
        .map((dataSource) => dataSource.sourceTypeID);

      return {
        ...state,
        selectedDataSourceSettings: {
          ...state.selectedDataSourceSettings,
          utilityServiceID,
          settings: selectedSettings,
          isDataSourceSettingsNew: false,
          usedDataSourceTypes,
        },
        drawersState: {
          ...state.drawersState,
          dataSourceSettingsDrawer: false,
        },
      };
    },
  ),
  on(OrganizationActions.setDataSourceSettingsParams, (state, params) => ({
    ...state,
    selectedDataSourceSettings: {
      ...state.selectedDataSourceSettings,
      settings: {
        ...state.selectedDataSourceSettings.settings,
        sourceTypeID: params.sourceTypeID,
        parameters: state.dataSourcesSettingsList.dataSourceTypes.find(
          (item) => item.sourceTypeID === params.sourceTypeID,
        ).parameters,
      },
    },
  })),
  on(OrganizationActions.GetDataSourceTypesSuccess, (state, sourceTypes) => ({
    ...state,
    dataSourcesSettingsList: {
      ...state.dataSourcesSettingsList,
      dataSourceTypes: sourceTypes.dataSourceTypes,
    },
  })),
  on(OrganizationActions.getDataSourceSettingsSuccess, (state, action) => ({
    ...state,
    dataSourcesSettingsList: {
      ...state.dataSourcesSettingsList,
      selectedDataSourceSettings: action.dataSourceSetting,
      isDataSourceSettingsNew: false,
    },
  })),
  on(OrganizationActions.validationError, (state, { ValidationErrors }) => {
    return {
      ...state,
      ValidationErrors,
    };
  }),
  on(OrganizationActions.resetOrganizationFilterAndSearch, (state) => ({
    ...state,
    filter: { ...initialOrganizationState.filter },
    search: '',
    organizations: { ...initialOrganizationState.organizations },
  })),
  on(OrganizationActions.showErrors, (state, { message }) => ({
    ...state,
    loading: false,
    // organizations: { ...initialOrganizationState.organizations },
    errors: message,
  })),
  on(OrganizationActions.resetOrganizationState, (state) => ({
    ...initialOrganizationState,
  })),

  on(
    OrganizationActions.getChannelConfigurationsSuccess,
    (state, { organizationUID, channelConfigurationList }) => ({
      ...state,
      organizationChannelConfigurationList: {
        organizationUID,
        channelConfigurationsList: channelConfigurationList,
      },
    }),
  ),
  on(
    OrganizationActions.addChannelConfigurationsSuccess,
    (state, { channelConfiguration }) => ({
      ...state,
      organizationChannelConfigurationList: {
        ...state.organizationChannelConfigurationList,
        channelConfigurationsList: [
          ...state.organizationChannelConfigurationList
            .channelConfigurationsList,
          channelConfiguration,
        ],
      },
      drawersState: {
        ...state.drawersState,
        channelConfigurationDrawer: true,
      },
    }),
  ),
  on(
    OrganizationActions.setSelectedChannelConfigurations,
    (state, { channelConfiguration }) => ({
      ...state,
      selectedChannelConfigurations: channelConfiguration,
      drawersState: {
        ...state.drawersState,
        channelConfigurationDrawer: false,
      },
    }),
  ),
  on(
    OrganizationActions.editChannelConfigurationsSuccess,
    (state, { channelConfiguration }) => ({
      ...state,
      organizationChannelConfigurationList: {
        ...state.organizationChannelConfigurationList,
        channelConfigurationsList:
          state.organizationChannelConfigurationList.channelConfigurationsList.map(
            (config) =>
              config.channelConfigurationUID ===
              channelConfiguration.channelConfigurationUID
                ? { ...channelConfiguration }
                : config,
          ),
      },
      drawersState: {
        ...state.drawersState,
        channelConfigurationDrawer: true,
      },
    }),
  ),
  on(
    OrganizationActions.deleteChannelConfigurationsSuccess,
    (state, { channelConfigurationUID }) => ({
      ...state,
      organizationChannelConfigurationList: {
        ...state.organizationChannelConfigurationList,
        channelConfigurationsList:
          state.organizationChannelConfigurationList.channelConfigurationsList.filter(
            (config) =>
              config.channelConfigurationUID !== channelConfigurationUID,
          ),
      },
    }),
  ),
);
