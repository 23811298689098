import { IMeterGroup, IMeterGroupView } from './meter-group.interface';

export interface IMeterGroupState {
  meterGroups: IMeterGroupView[];
  filteredMetersGroup: IMeterGroupView[];
  selectedMeterGroup: IMeterGroup;
  lastGroupUpdated: IMeterGroup | null;

  drawersState: {
    editCreateMeterGroupDrawer: boolean;
  };
  ValidationErrors: any;
  newMeterGroup: boolean;
  searchText: string;
  searchResultCount: number | null;
}

export const initialMeterGroupState: IMeterGroupState = {
  meterGroups: [],
  filteredMetersGroup: [],

  selectedMeterGroup: {
    meterGroupUID: '',
    meterGroupName: '',
    organizationUID: '',
    parentMeterGroupUID: null,
    meterUIDs: [],
  },
  lastGroupUpdated: null,
  drawersState: {
    editCreateMeterGroupDrawer: false,
  },
  ValidationErrors: null,
  newMeterGroup: false,
  searchText: '',
  searchResultCount: null,
};
