import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { environment } from 'src/environments/environment';
import {
  IMeterGroup,
  IMeterGroupView,
} from '@features/meter-group/meter-group-store/meter-group.interface';

@Injectable({
  providedIn: 'root',
})
export class MeterGroupsService {
  apiBaseUrl = '';
  endpoint = 'metergroups';

  constructor(private http: HttpClient) {
    this.apiBaseUrl = environment.apiBaseUrl;
  }

  getMetersList(metersGroupUID: string): Observable<any> {
    return this.http.get<any>(
      `${this.apiBaseUrl}${this.endpoint}/${metersGroupUID}/meters`,
    );
  }

  getMeterGroups(): Observable<IMeterGroupView[]> {
    return this.http.get<any>(`${this.apiBaseUrl}${this.endpoint}`, {});
  }

  addMeterGroup(MeterGroupUpdated: IMeterGroup): Observable<any> {
    if (!MeterGroupUpdated) {
      return throwError(() => new Error('Data is required'));
    }
    return this.http.post<any>(`${this.apiBaseUrl}${this.endpoint}`, {
      meterGroupName: MeterGroupUpdated.meterGroupName,
      parentMeterGroupUID: MeterGroupUpdated.parentMeterGroupUID,
      meterUIDs: MeterGroupUpdated.meterUIDs ?? [],
    });
  }

  updateMeterGroup(MeterGroupUpdated: IMeterGroup): Observable<any> {
    if (!MeterGroupUpdated) {
      return throwError(() => new Error('Data is required'));
    }
    return this.http.put<any>(
      `${this.apiBaseUrl}${this.endpoint}/${MeterGroupUpdated.meterGroupUID}`,
      {
        meterGroupName: MeterGroupUpdated.meterGroupName,
        parentMeterGroupUID: MeterGroupUpdated.parentMeterGroupUID,
        meterUIDs: MeterGroupUpdated.meterUIDs ?? [],
      },
    );
  }

  deleteMeterGroup(deleteMeterGroupUID: string): Observable<any> {
    if (!deleteMeterGroupUID) {
      return throwError(() => new Error('Data is required'));
    }
    return this.http.delete<any>(
      `${this.apiBaseUrl}${this.endpoint}/${deleteMeterGroupUID}`,
    );
  }
}
